import React, { useState } from "react"
import { getImage} from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import "../../fonts/fontawesome"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram
} from "@fortawesome/free-brands-svg-icons"
import {
  faBars,
  faEnvelope,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons"

import "./rsmenu-main.css"
import "./navigation.scss"
export default function Navigation({ }) {

  const data = useStaticQuery(graphql`
    query {
      wp {
        siteSettings {
          siteSettings {
            cta {
              title
              url
            }
            facebookLink {
              url
            }
            instagramLink {
              url
            }
            linkedinLink {
              url
            }
            menu {
              navLink {
                title
                url
              }
            }
            specialField {
              subfield {
                subfieldLink {
                  title
                  url
                }
              }
              title
            }
            twitterLink {
              url
            }
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 70, width: 56)
                }
              }
            }
            supportEmail
          }
        }
      }
    }
  `)

  const [openMenuToggle, setOpenMenuToggle] = useState(false)

  const [activeMenu, setActiveMenu] = useState(false)
  const toggleMenu = () => {
    setActiveMenu(!activeMenu)
  }
  //Navigation Main Field Links
  const navData = data.wp?.siteSettings?.siteSettings

  // Top level nav links
  const mainFields = data.wp?.siteSettings?.siteSettings.menu
  //Special Field with sub-links nested
  const specialField = data.wp?.siteSettings?.siteSettings?.specialField?.subfield
  return (
    <>
      {/* <!--Header area start here--> */}
      <header className="">
        <div className="header-top-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="header-top-left">
                  <ul>
                    <li>
                      <a href={`mailto:${navData.supportEmail}`}
                        className="link-header"
                      >
                        <FontAwesomeIcon
                          className="text-black icon-header"
                          icon={faEnvelope}
                        />{" "}
                        {navData?.supportEmail}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="social-media-area">
                  <ul>
                    <li>
                      <a
                        className="link-header"
                        href={`${navData?.facebookLink?.url}`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Facebook"
                      >
                        <FontAwesomeIcon
                          className="text-black icon-header"
                          icon={faFacebook}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        className="link-header"
                        href={`${navData?.twitterLink?.url}`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Twitter"
                      >
                        <FontAwesomeIcon
                          className="text-black icon-header"
                          icon={faTwitter}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        className="link-header"
                        href={`${navData?.instagramLink?.url}`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Instagram"
                      >
                        <FontAwesomeIcon
                          className="text-black icon-header"
                          icon={faInstagram}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        className="link-header"
                        href={`${navData?.linkedinLink?.url}`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Linkedin"
                      >
                        <FontAwesomeIcon
                          className="text-black icon-header"
                          icon={faLinkedin}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-middle-area menu-sticky">
          <div className="container">
            <div className="row">
              <div className="col-lg-1 col-md-12 col-sm-12 logo">
                <Link to="/">
                  <GatsbyImage
                    image={getImage(navData?.logo?.localFile)}
                    alt="Raiders-logo"
                    className="img-header mr-0"
                  />
                </Link>
              </div>
              <div className="col-lg-11 col-md-12 col-sm-12 mobile-menu">
                <div className="main-menu">
                  <div
                    onClick={() => {
                      setOpenMenuToggle(!openMenuToggle)
                    }}
                    className="rs-menu-toggle"
                    aria-label="Menu"
                  >
                    <FontAwesomeIcon
                      className="text-primary"
                      icon={faBars}
                      style={{ width: "15px", height: "15px" }}
                    />{" "}
                    Menu
                  </div>
                  <nav className="rs-menu">
                    <ul
                      className={`nav-menu ${
                        openMenuToggle ? "" : "d-none d-lg-block d-xl-block"
                      }`}
                    >
                      {/* <!-- Home --> */}
                      <li className="current-menu-item current_page_item menu-item-has-children">
                        <Link
                          to={`${mainFields[0]?.navLink?.url}`}
                          className="nav-rs-font"
                        >
                          {mainFields[0]?.navLink?.title}
                        </Link>
                      </li>
                      {/* <!-- End Home --> */}
                      <li className="menu-item-has-children">
                        <Link
                          to="#"
                          className="nav-rs-font"
                          onClick={toggleMenu}
                        >
                          {navData?.specialField?.title}
                          <span className="pl-1">
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              style={{ width: "14px", height: "16px" }}
                            />
                          </span>
                        </Link>
                        <ul
                          className={`sub-menu ${
                            activeMenu ? "activeMenu" : ""
                          }`}
                        >
                          {specialField?.map((el, index) => {
                            return (
                              <li key={index}>
                                <Link
                                  to={`${el?.subfieldLink?.url}`}
                                  className="sub-menu-li"
                                >
                                  {el?.subfieldLink?.title}
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      </li>
                      {mainFields.slice(1).map((el, index) => {
                        return (
                          <li key={index}>
                            <Link
                              to={`${el?.navLink?.url}`}
                              className="nav-rs-font"
                            >
                              {el?.navLink?.title}
                            </Link>
                          </li>
                        )
                      })}
                      <li className="member-menu-li">
                        <Link
                          className="member-menu-btn text-black"
                          to={`${navData?.cta?.url}`}
                          style={{ color: "#111" }}
                        >
                          {navData?.cta?.title}
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!--Header area end here--> */}
    </>
  )
}
