import React from "react"
import FooterGlobal from "../footer-global/footer-global"
import Navigation from "../navigation/navigation"
import Seo from "../Seo/Seo"

const DefaultLayout = ({ data, children }) => {

    return (
        <>
            <Seo
                title={data?.wpPage?.seo?.title}
                description={data?.wpPage?.seo?.metaDesc}
                featuredImage={
                    data?.wpPage?.seo?.opengraphImage?.localFile.childImageSharp
                        .gatsbyImageData
                }
            />
            <Navigation />
            {children}
            <FooterGlobal />
        </>

    )
}

export default DefaultLayout



